import React from 'react'
import { MinusIcon } from 'icons/Icons'
import * as S from './Header.style'
import { DARK_JUNGLE_GREEN } from 'constants/colors'

interface Props {
  parentRef: any
  popoverRef: any
  header: string
  widgetColor: string
  widgetLogo: string
  isFullScreenWidget: boolean
  openPopover: boolean
  handleHiddenWidget: () => void
  handleOpenPopover: () => void
}

export const Header: React.FC<Props> = ({
  parentRef,
  header,
  widgetColor,
  widgetLogo,
  isFullScreenWidget,
  handleHiddenWidget,
}) => {
  return (
    <S.Header ref={parentRef} color={widgetColor} isFullScreenWidget={isFullScreenWidget}>
      <S.NameWrap>
        <S.Logo src={widgetLogo} alt={header} />
        <S.Name tabIndex={0}>{header}</S.Name>
      </S.NameWrap>
      <S.ButtonsWrap>
        <S.Button onClick={handleHiddenWidget} id="wrap-widget-icon" aria-label="wrap widget" tabIndex={0}>
          <MinusIcon color={DARK_JUNGLE_GREEN} />
        </S.Button>
      </S.ButtonsWrap>
    </S.Header>
  )
}
