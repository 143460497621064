import styled from 'styled-components'
import { DARK_JUNGLE_GREEN, _15PercentTransparencyInHex } from 'constants/colors'

const Header = styled.div<{ color: string; isFullScreenWidget: boolean }>`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  background: ${({ color }) => `${color}${_15PercentTransparencyInHex}`};
  height: 66px;
  position: relative;
  border-radius: ${({ isFullScreenWidget }) => (isFullScreenWidget ? '0' : '10px 10px 0 0')};
`

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Logo = styled.img`
  height: 50px;
  width: 50px;
  cursor: pointer;
  animation-duration: 0.3s;
  animation-name: appear;
  margin: 0 16px 0 0;
  border-radius: 50%;
`

const Name = styled.p`
  color: ${DARK_JUNGLE_GREEN};
  font-size: 1rem;
  font-weight: 400;
`

const ButtonsWrap = styled.div`
  display: flex;
`

const Button = styled.button`
  cursor: pointer;
  user-select: none;
  margin-left: 15px;
  border: none;
  animation-duration: 0.3s;
  animation-name: appearIn;
  background: transparent;
  padding: 0;
`

export { Header, NameWrap, Logo, Name, ButtonsWrap, Button }
