import { ChatRoleMap } from '../../../constants/index'

export const checkShowLogo = (messages, index) => {
  return (
    messages[index].role === ChatRoleMap.assistant &&
    (messages[index + 1]
      ? messages[index].role === ChatRoleMap.assistant && messages[index + 1].role !== ChatRoleMap.assistant
      : messages.length === index + 1 && messages[index].role === ChatRoleMap.assistant)
  )
}

export const defineMessageBorder = (messages, index) => {
  //NOSONAR
  if (messages[index].isFromBot) {
    const hasPrevReceived = messages[index - 1] && messages[index - 1].isFromBot && !messages[index - 1].action
    const hasNextReceived = messages[index + 1] && messages[index + 1].isFromBot && !messages[index + 1].action

    if (hasNextReceived && (!messages[index - 1] || !messages[index - 1].isFromBot || messages[index - 1].action))
      return '17px 17px 17px 2px'
    if (hasPrevReceived && hasNextReceived) return '2px 17px 17px 2px'
    if (hasPrevReceived && (!messages[index + 1] || !messages[index + 1].isFromBot || messages[index + 1].action))
      return '2px 17px 17px 17px'
  }

  if (!messages[index].isFromBot) {
    // eslint-disable-next-line no-redeclare
    const hasPrevReceived = messages[index - 1] && !messages[index - 1].isFromBot && !messages[index - 1].action
    // eslint-disable-next-line no-redeclare
    const hasNextReceived = messages[index + 1] && !messages[index + 1].isFromBot && !messages[index + 1].action

    if (hasNextReceived && (!messages[index - 1] || messages[index - 1].isFromBot || messages[index - 1].action))
      return '17px 17px 2px 17px'
    if (hasPrevReceived && hasNextReceived) return '17px 2px 2px 17px'
    if (hasPrevReceived && (!messages[index + 1] || messages[index + 1].isFromBot || messages[index + 1].action))
      return '17px 2px 17px 17px'
  }
}

export const messageContainerSize = (device, textEnable, isFullScreenWidget, scrollBehavior, screenSize) => {
  const marginBottom = 65
  const horizontalMargin = 0
  const chatInputHeightAndPadding = device?.height > 0 ? 100 : 0
  const needCustomSize = device?.height > 0 || device?.width > 0
  const heightWithoutInput = needCustomSize ? `${device.height - marginBottom}px` : '465px'
  const heightWithInput = needCustomSize ? `${device.height - chatInputHeightAndPadding}px` : '390px'
  const maxHeightWithoutInput = needCustomSize ? `${device.height - marginBottom}px` : `${screenSize.height - 186}px`
  const maxHeightWithInput = needCustomSize
    ? `${device.height - chatInputHeightAndPadding}px`
    : `${screenSize.height - 246}px`
  const widgetFullScreenHeight = textEnable ? `calc(100% - ${chatInputHeightAndPadding}px)` : '100%'

  if (isFullScreenWidget) {
    return {
      height: widgetFullScreenHeight,
      width: needCustomSize ? `${device.width - horizontalMargin}px` : '100%',
      scrollBehavior,
    }
  }

  return {
    height: textEnable ? heightWithInput : heightWithoutInput,
    width: needCustomSize ? `${device.width - horizontalMargin}px` : '100%',
    maxHeight: textEnable ? maxHeightWithInput : maxHeightWithoutInput,
    scrollBehavior,
  }
}

export const chatSize = (height, width, isFullScreenWidget) => {
  const headerHeight = 65

  if (isFullScreenWidget) {
    return {
      height: `calc(100% - ${headerHeight}px)`,
      width: '100%',
    }
  }

  return {
    height: height > 0 ? `${height - headerHeight}px` : 'auto',
    width: width > 0 ? `${width}px` : '100%',
  }
}
