import React, { useContext, useEffect, useMemo, useRef, JSX } from 'react'
import * as ReactDOM from 'react-dom'
import xss from 'xss'

import { generateNewTabName } from 'helper/generateNewTabName'
import { wrapTextAddLinks } from 'helper/wrapTextAddLinks'
import { SettingsType } from 'models/SettingsType'
import { GHOST_WHITE, _10PercentTransparencyInHex } from 'constants/colors'
import classes from './styles.module.scss'
import { DeviceType } from 'models/DeviceType'
import { IframeContext } from 'context/IframeContext'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { xssOptions } from 'config/xssOptions'
import { Message } from 'ai'
import { ChatRoleMap } from 'constants/index'

export const messageWidthText = (screenWidth, color, messageBorder, isRtl) =>
  ({
    maxWidth: screenWidth ? (screenWidth * 70) / 100 + 'px' : '254px',
    whiteSpace: 'pre-wrap',
    borderRadius: messageBorder,
    direction: isRtl ? 'rtl' : 'ltr',
  } as React.CSSProperties)

interface TextMessageProps {
  postMessage: (a: any) => void
  message: Message
  device: DeviceType
  settings: SettingsType
  messageBorder: string
  showLogo: boolean
  isLastMessage: boolean
  addReaction?: (e: any, reactionValue: string, messageId: string) => void
  isMobile: boolean
  isBotReplying: boolean
}

const enterKeyCode = 13

const TextMessage = ({
  message,
  device,
  settings,
  messageBorder,
  isBotReplying,
  isLastMessage,
}: TextMessageProps): JSX.Element => {
  const { handleOpenIframe } = useContext(IframeContext)
  const output = useRef(null)
  const isAssistantMessage = message.role === ChatRoleMap.assistant

  const color = useMemo(() => (message.role === ChatRoleMap.assistant ? GHOST_WHITE : settings.color), [])
  const bubbleClass = useMemo(
    () => (message.role === ChatRoleMap.assistant ? classes.bubbleReceived : classes.bubbleSent),
    [],
  )
  const preventFocus = usePreventFocus()

  useEffect(() => {
    linkEditorListener()
  }, [preventFocus])

  const linkEditorListener = () => {
    // @ts-ignore
    const node = ReactDOM.findDOMNode(output.current).querySelectorAll('.linkEdited')
    const links = [...node]

    links.length > 0 &&
      links.forEach(link => {
        const extension = JSON.parse(link.getAttribute('data-extension'))
        const url = link.getAttribute('data-href')

        link.tabIndex = preventFocus ? -1 : 0
        link.ariaLabel = `${link.innerText} - link`

        if (extension) {
          link.onclick = () => handleOpenIframe(url)
          link.onkeypress = e => handleEnterLink(e, () => handleOpenIframe(url))
        } else {
          link.onclick = () => openNewTab(url)
          link.onkeypress = e => handleEnterLink(e, () => openNewTab(url))
        }
      })
  }

  const handleEnterLink = (e, callback) => {
    const keycode = e.keyCode || e.which

    if (keycode === enterKeyCode) {
      callback()
    }
  }

  const openNewTab = url => {
    window.open(url, generateNewTabName())
  }

  const bubbleBgStyle = {
    ...(isAssistantMessage
      ? { background: GHOST_WHITE }
      : { background: `${settings.color}${_10PercentTransparencyInHex}` }),
  }

  const blinkingCursor =
    isBotReplying && isLastMessage && isAssistantMessage ? `<span class=${classes.gptBlinkingCursor} />` : ''

  return (
    <span
      ref={output}
      data-autotest="text-message-container"
      className={classes.bubbleContainer}
      style={{ ...bubbleBgStyle }}>
      {isAssistantMessage && (
        <span style={{ color: settings.color, marginBottom: '4px', fontSize: '14px' }}>{settings.header}</span>
      )}
      <span
        className={`${bubbleClass} ${classes.bubble}`}
        tabIndex={preventFocus ? -1 : 0}
        style={messageWidthText(device.width, color, messageBorder, false)}
        dangerouslySetInnerHTML={{
          __html: xss(wrapTextAddLinks(message.content, preventFocus), xssOptions) + blinkingCursor,
        }}
        data-autotest="text-message"
      />
    </span>
  )
}

export default TextMessage
