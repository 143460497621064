import { MutableRefObject } from 'react'

export const useScrollChatContainer = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
): { scrollToBottom: () => void } => {
  const scrollToBottom = () => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  return { scrollToBottom }
}
