export const fullScreenWidth = '68vw'
export const fullScreenHeight = '100vh'

export const checkIsRightButtonNeeded = (screen, widgetIsOpen, isFullScreenWidget) => {
  const device = screen.width > 0 && screen.height > 0
  if (!device && !isFullScreenWidget) {
    return true
  }
  return device && !widgetIsOpen
}

export const transitionSettingsLauncher = {
  timeout: 25,
}

export const transitionSettingsContainer = {
  timeout: 50,
}

export const popupMessageDelay = 1000 * 5
export const unreadIconDelay = 1000 * 3

export const detectSiteLanguageOrDefault = url => {
  if (url === '') {
    return 'en'
  }

  const parsedUrl = new URL(url)
  const search = new URLSearchParams(parsedUrl.search)
  if (search.has('lang')) {
    return search.get('lang')
  }

  return 'en'
}
