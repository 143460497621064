import React, { Dispatch, SetStateAction } from 'react'

import '../Message.css'
import { MessageWrapper } from '../MessageWrapper'
import { SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { Message } from 'ai'
import TextMessage from '../TextMessage'
import { Append, QuickReplies } from 'types'
import { WidgetEvent } from 'helper/eventHelper'

interface Props {
  message: Message
  quickReplies: string[]
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  showQuickReplies: boolean
  postMessage: (m: MessageType) => void
  parentURL: string
  showLogo: boolean
  device: DeviceType
  settings: SettingsType
  isFullScreenWidget: boolean
  messageBorder: string
  isMobile: boolean
  isLastMessage: boolean
  showReactionElementText: boolean
  isBotReplying: boolean
  append: Append
  trackWidgetEvent: (event: WidgetEvent) => void
}

const MessageBody: React.FC<Props> = ({
  message,
  quickReplies,
  setQuickReplies,
  showQuickReplies,
  trackWidgetEvent,
  postMessage,
  showLogo,
  settings,
  isLastMessage,
  device,
  messageBorder,
  isMobile,
  isBotReplying,
  append,
}) => {
  return (
    <>
      <MessageWrapper
        showLogo={showLogo}
        message={message}
        quickReplies={quickReplies}
        setQuickReplies={setQuickReplies}
        showQuickReplies={showQuickReplies}
        append={append}
        trackWidgetEvent={trackWidgetEvent}
        settings={settings}
        postMessage={postMessage}
        isLastMessage={isLastMessage}>
        <TextMessage
          device={device}
          settings={settings}
          postMessage={postMessage}
          message={message}
          messageBorder={messageBorder}
          showLogo={showLogo}
          isLastMessage={isLastMessage}
          isBotReplying={isBotReplying}
          isMobile={isMobile}
        />
      </MessageWrapper>
    </>
  )
}

export default MessageBody
