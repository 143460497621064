import 'react-app-polyfill/ie9'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { AppContainer } from './app/AppContainer'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
// eslint-disable-next-line no-unused-vars
import * as globalHelpers from './helper'
import { IframeProvider } from './context/IframeContext'
import { AriaLiveProvider } from './context/AriaLiveContext'
import { ScrollToButtonProvider } from './context/ScrollToButtonContext'
import { BrowserStorageContext, BrowserStorageProvider } from './context/BrowserStorageContext'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracePropagationTargets: [],
    }),
    new Sentry.Integrations.Breadcrumbs({ console: true, fetch: true }),
  ],
  tracesSampleRate: 0,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Sentry.ErrorBoundary>
    <AriaLiveProvider>
      <IframeProvider>
        <ScrollToButtonProvider>
          <BrowserStorageProvider>
            <BrowserStorageContext.Consumer>
              {valueBrowserStorage => (
                <AppContainer
                  updateBrowserStorage={valueBrowserStorage.updateBrowserStorage}
                  browserStorage={valueBrowserStorage.browserStorage}
                  initializeStorage={valueBrowserStorage.initializeStorage}
                />
              )}
            </BrowserStorageContext.Consumer>
          </BrowserStorageProvider>
        </ScrollToButtonProvider>
      </IframeProvider>
    </AriaLiveProvider>
  </Sentry.ErrorBoundary>,
)
