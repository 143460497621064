export type ChatRole = 'user' | 'assistant' | 'system'
export const historySize = 500

export type ChatMessage = {
  id: string
  chatId: number
  role: ChatRole
  message: string
  timestamp: string
}

export type NormalizedChatMessage = ChatMessage & {
  content: string
  quickReplies?: string[]
}

export type ChatHistory = NormalizedChatMessage[]

export const fetchChatHistory = ([backendURL, publicBotId, chatId, historySize]: [
  string,
  string,
  string,
  number,
]): Promise<undefined | ChatHistory> => {
  return fetch(`${backendURL}/api/bot/${publicBotId}/chat/${chatId}?size=${historySize}&cursor=${new Date()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(data =>
      data.map(msg => {
        return {
          ...msg,
          content: msg.message,
        }
      }),
    )
}
