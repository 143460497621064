import React from 'react'

const AriaLiveContext = React.createContext({
  isEnableAriaLive: false,
  setIsEnableAriaLive: undefined,
})

const AriaLiveProvider = props => {
  const [isEnableAriaLive, setIsEnableAriaLive] = React.useState(false)

  const value = React.useMemo(
    () => ({
      isEnableAriaLive,
      setIsEnableAriaLive,
    }),
    [isEnableAriaLive],
  )

  return <AriaLiveContext.Provider value={value}>{props.children}</AriaLiveContext.Provider>
}

export { AriaLiveProvider, AriaLiveContext }
