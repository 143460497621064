import React, { FC, useState } from 'react'
import App from './App'
import { useChat } from 'ai/react'
import { API_URL } from 'config/config'
import { getQuickReplies } from 'api/api'
import { createChatOptions } from 'helper/createChatOptions'
import { ActionTypeMap } from 'constants/index'
import { QuickReplies } from 'types'

interface AppContainerProps {
  initializeStorage: (websiteLocation: string, storage) => void
  updateBrowserStorage: (storage) => void
  browserStorage: Record<string, string | null | undefined>
}

const defaultQuickRepliesState = { messageId: '', quickReplies: [] }

export const AppContainer: FC<AppContainerProps> = ({ initializeStorage, updateBrowserStorage, browserStorage }) => {
  const [initialMessages, setInitialMessages] = useState([])
  const [quickReplies, setQuickReplies] = useState<QuickReplies>(defaultQuickRepliesState)
  const [currentUrl, setCurrentUrl] = useState<string | null>(null)

  const { messages, input, handleInputChange, handleSubmit, isLoading, append } = useChat({
    api: `${API_URL}/api/public/bot/${browserStorage?.botId}/chat/${browserStorage?.chatId}`,
    headers: {
      'Content-Type': 'application/json',
      'x-widget': 'true',
    },
    body: {
      currentUrl,
    },
    initialMessages,
    onFinish(lastMessage) {
      handleQuickReplies(lastMessage.id)
    },
  })

  const handleQuickReplies = (tempId: string) => {
    if (browserStorage?.quickRepliesEnabled) {
      getQuickReplies(browserStorage?.botId, browserStorage?.chatId, tempId).then(quickRepliesResponse => {
        setQuickReplies(quickRepliesResponse || defaultQuickRepliesState)
      })
    }
  }

  const submitMessage = e => {
    browserStorage?.botId && localStorage.setItem(`widgetChatInitiated-${browserStorage.botId}`, 'true')
    handleSubmit(e, createChatOptions(ActionTypeMap.TEXT_MESSAGE))
  }

  return (
    <App
      initialMessages={initialMessages}
      setInitialMessages={setInitialMessages}
      messages={messages}
      quickReplies={quickReplies}
      setQuickReplies={setQuickReplies}
      input={input}
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      handleSubmit={submitMessage}
      updateBrowserStorage={updateBrowserStorage}
      browserStorage={browserStorage}
      initializeStorage={initializeStorage}
      append={append}
      setCurrentUrl={setCurrentUrl}
    />
  )
}
