import styled from 'styled-components'
import {
  WHITE,
  MINE_SHAFT_BLACK,
  _15PercentTransparencyInHex,
  _6PercentTransparencyInHex,
  DARK_JUNGLE_GREEN,
} from 'constants/colors'

const UserInput = styled.div<{ textDisabled?: boolean; isMobile?: boolean }>`
  display: ${({ textDisabled }) => (textDisabled ? 'flex' : 'none')};
  position: relative;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 4px;

  & textarea {
    font-size: ${({ isMobile }) => (isMobile ? '16px' : '14px')};
  }
`

const Input = styled.input`
  background: ${WHITE};
  width: 100%;
  height: 40px;
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px;
  border-radius: 10px;
  color: ${MINE_SHAFT_BLACK};
  border: 1px solid ${props => props.color};
  resize: none;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  &:focus {
    outline: 0;
    touch-action: none;
    box-shadow: 0 0 0 3px ${props => props.color + _15PercentTransparencyInHex};
  }
`

const Button = styled.button<{ isMobile?: boolean }>`
  padding: 4px 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.color + _6PercentTransparencyInHex};
  border: 1px solid ${props => props.color};
  color: ${DARK_JUNGLE_GREEN};
  border-radius: 4px;
  gap: 4px;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  height: ${({ isMobile }) => (isMobile ? '29.5px' : '28px')};
`

const ButtonWrapper = styled.div`
  right: 8px;
  bottom: 4px;
  position: absolute;
  background: white;
`

export { UserInput, Input, Button, ButtonWrapper }
