import styled, { keyframes } from 'styled-components'
import {
  CORNFLOWER_BLUE,
  WHITE,
  _0PercentTransparencyInHex,
  _20PercentTransparencyInHex,
  _5PercentTransparencyInHex,
} from 'constants/colors'
import { bubbleButtonPadding } from 'constants/config'

const animatePulse = (configColor: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${configColor}${_20PercentTransparencyInHex},
                0 0 0 0 ${configColor}${_20PercentTransparencyInHex};
  }
  40% {
    box-shadow: 0 0 0 12px ${configColor}${_0PercentTransparencyInHex},
                0 0 0 0 ${configColor}${_5PercentTransparencyInHex};
  }
  80% {
    box-shadow: 0 0 0 12px ${configColor}${_0PercentTransparencyInHex},
                0 0 0 0 ${configColor}${_5PercentTransparencyInHex};
  }
  100% {
    box-shadow: 0 0 0 0 ${configColor}${_0PercentTransparencyInHex},
                0 0 0 0 ${configColor};
  }
`

const Button = styled.button<{ color: string }>`
  background-color: ${({ color }) => color || CORNFLOWER_BLUE};
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: none;
  padding: 0;
  &:first-child {
    transition: 0.4s all;
  }
  &:hover {
    transform: scale(1.1);
  }

  animation: ${({ color }) => animatePulse(color)} 3s linear infinite;
`

const CustomButtonWrapper = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  height: 60px;
  width: 60px;
  background: transparent;
  cursor: pointer;
`

const Logo = styled.img`
  width: 22px;
  animation-duration: 0.4s;
  animation-name: appear;
`

const Container = styled.div<{ isButtonNeeded: string }>`
  position: relative;
  padding: ${bubbleButtonPadding}px;
  display: ${({ isButtonNeeded }) => !isButtonNeeded && 'none'};
`

const animateUnreadLabel = keyframes`
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
}
`

const UnreadLabel = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 20px;
  width: 20px;
  display: flex;
  background: #d93d42;
  color: ${WHITE};
  border-radius: 50%;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  border: 1px solid ${WHITE};
  animation: ${animateUnreadLabel} 0.5s linear 1;
`

export { Button, Logo, Container, UnreadLabel, CustomButtonWrapper }
