import { useEffect, useRef } from 'react'

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    if (ref.current !== value) {
      ref.current = value
    }
  })
  return ref.current
}
