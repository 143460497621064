import React, { useContext } from 'react'

import '../../message/Message.css'
import { chatSize } from './config'
import MessageContainer from './MessageContainer'
import Iframe from '../../Iframe'
import classes from './styles.module.scss'
import UserInput from '../../UserInput'
import { IframeContext } from '../../../context/IframeContext'

const Chat = ({
  messages,
  quickRepliesEnabled,
  quickReplies,
  setQuickReplies,
  trackWidgetEvent,
  isHiddenWidget,
  parentURL,
  device,
  textDisabled,
  inputTogglerValue,
  openWidget,
  settings,
  isFullScreenWidget,
  isMobile,
  screenSize,
  input,
  handleInputChange,
  handleSubmit,
  isLoading,
  conversationStarters,
  hideConversationStarters,
  append,
}) => {
  const { showIframe } = useContext(IframeContext)
  const isRtl = messages && messages.length && messages[messages.length - 1].isRtl

  const addUTMMediumParam = () => {
    if (!settings.chatFooterLink) return ''
    const footerURL = new URL(settings.chatFooterLink)
    const searchParams = new URLSearchParams(footerURL.search)

    if (searchParams.size) {
      searchParams.set('utm_medium', 'bubble')
      footerURL.search = searchParams.toString()
    }

    return footerURL.toString()
  }

  return (
    <div className={classes.wrap} style={chatSize(device?.height, device?.width, isFullScreenWidget)}>
      <MessageContainer
        screenSize={screenSize}
        device={device}
        parentURL={parentURL}
        messages={messages}
        quickRepliesEnabled={quickRepliesEnabled}
        quickReplies={quickReplies}
        setQuickReplies={setQuickReplies}
        trackWidgetEvent={trackWidgetEvent}
        isHiddenWidget={isHiddenWidget}
        openWidget={openWidget}
        settings={settings}
        isMobile={isMobile}
        id="message-container"
        isLoading={isLoading}
        textDisabled={textDisabled}
        isFullScreenWidget={isFullScreenWidget}
        conversationStarters={conversationStarters}
        hideConversationStarters={hideConversationStarters}
        append={append}
      />

      <UserInput
        input={input}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        setQuickReplies={setQuickReplies}
        settings={settings}
        isFullScreenWidget={isFullScreenWidget}
        textDisabled={textDisabled}
        inputTogglerValue={inputTogglerValue}
        isRtl={isRtl}
        device={device}
        screenSize={screenSize}
        disableInput={isLoading}
        isMobile={isMobile}
      />

      {!settings.whiteLabel && (
        <a
          href={addUTMMediumParam(settings.chatFooterLink)}
          target="_blank"
          rel="noreferrer"
          className={`${classes.footerWatermark} ${isMobile ? classes.mobileMargin : ''}`}>
          {settings.chatFooterLabel}
        </a>
      )}

      {showIframe && (
        <Iframe
          device={device}
          color={settings.color}
          isFullScreenWidget={isFullScreenWidget}
          screenSize={screenSize}
        />
      )}
    </div>
  )
}

export default Chat
