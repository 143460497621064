import styled from 'styled-components'
import { RIBBON_BLUE, WHITE } from 'constants/colors'
import { DeviceType } from 'models/DeviceType'

const Button = styled.button<{ color: string }>`
  background: ${({ color }) => color || RIBBON_BLUE};
  position: absolute;
  top: 0;
  left: 0;
  height: 65px;
  width: 80px;
  z-index: 1;
  padding: 10px 14px 10px 0px;
  cursor: pointer;
  border: none;
`

const Image = styled.img`
  width: 10px;
  height: 15px;
`

const Iframe = styled.iframe<{ device: DeviceType; isFullScreenWidget: boolean; screenSize: DeviceType }>`
  position: absolute;
  top: 66px;
  left: 0;
  z-index: 2;
  background-color: ${WHITE};
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: ${({ isFullScreenWidget, device }) => (isFullScreenWidget ? '100%' : `${device.width || 360}px`)};
  height: ${({ isFullScreenWidget, device }) => {
    const headerHeight = 66
    if (isFullScreenWidget) {
      return `calc(100% - ${headerHeight}px)`
    } else if (device.height) {
      return `${device.height - headerHeight}px`
    } else {
      return '470px'
    }
  }};
  max-height: ${({ isFullScreenWidget, device, screenSize }) => {
    const headerHeight = 66
    if (isFullScreenWidget) {
      return `calc(100% - ${headerHeight}px)`
    } else if (device.height) {
      return `${device.height - headerHeight}px`
    } else {
      return `${screenSize.height - 186}px`
    }
  }};
`
export { Button, Image, Iframe }
