import styled from 'styled-components'
import { WHITE, COMET_BLUE, _15PercentTransparencyInHex } from 'constants/colors'

const MessagesPreview = styled.div<{ isPopupsNeeded: string; alignItems: string }>`
  display: ${({ isPopupsNeeded }) => (isPopupsNeeded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  padding: 10px 12px 0 12px;
`

const Popup = styled.button<{ width: number; isMobile: boolean; hideConversationStarters: boolean }>`
  display: flex;
  background: ${WHITE};
  box-shadow: ${({ width }) => (width ? '0px 0px 13px rgba(51, 51, 51, 0.1)' : '0px 0px 30px rgba(51, 51, 51, 0.1)')};
  border-radius: 4px;
  align-items: center;
  animation: toggleOpacity 0.7s;
  cursor: pointer;
  max-width: ${({ width }) => (width ? width - 120 : 380)}px;
  margin: ${({ hideConversationStarters }) => (hideConversationStarters ? '0 0 0 0' : '0 0 16px 0')};
  border: none;
  position: relative;
  min-height: 48px;

  & > span#closePopup {
    display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};
  }

  &:hover > span#closePopup {
    display: flex;
  }
`

const Icon = styled.span`
  cursor: pointer;
  align-items: center;
  background: ${WHITE};
  border: none;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: -9px;
  right: -10px;
`

const Text = styled.p`
  font-size: 14px;
  color: ${COMET_BLUE};
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  word-break: break-word;
  padding: 10px;
  text-align: left;
`

const ConversationStarter = styled.div<{ width: number }>`
  display: flex;
  box-shadow: ${({ width }) => (width ? '0px 0px 13px rgba(51, 51, 51, 0.1)' : '0px 0px 30px rgba(51, 51, 51, 0.1)')};
  background: ${({ color }) => `${color}${_15PercentTransparencyInHex}`};
  align-items: center;
  cursor: pointer;
  max-width: fit-content;
  border: none;
  min-height: 40px;
  border-radius: 4px;

  &:hover {
    background: ${({ color }) => color};

    & > p {
      color: white;
    }
  }
`

const ConversationStarterContainer = styled.div<{ width: number; widgetXPosition: string }>`
  background: ${WHITE};
  margin-bottom: 12px;
  border-radius: 4px;
  margin-left: ${({ widgetXPosition, width }) => (widgetXPosition === 'right' && width ? '10px' : '0')}};
  margin-right: ${({ widgetXPosition, width }) => (widgetXPosition === 'left' && width ? '10px' : '0')};

  &:last-child {
    margin-bottom: 0;
  }
`

export { MessagesPreview, Popup, Icon, Text, ConversationStarter, ConversationStarterContainer }
