import { _15PercentTransparencyInHex } from 'constants/colors'
import styled from 'styled-components'

const QuickReplyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 12px;
  padding-right: 16px;
  padding-left: 40px;
`
const QuickReplyItem = styled.div<{ color: string }>`
  font-size: 14px;
  line-height: 20px;
  padding: 10px 8px;
  background: ${({ color }) => `${color}${_15PercentTransparencyInHex}`};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${({ color }) => color};
    color: white;
  }
`

export { QuickReplyList, QuickReplyItem }
