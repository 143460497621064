import React, { JSX } from 'react'

import { _15PercentTransparencyInHex } from 'constants/colors'
import { ActionTypeMap, ChatRoleMap } from 'constants/index'

import classes from './styles.module.scss'
import { CONVERSATION_STARTER_CLICKED, WidgetEvent, createEvent } from 'helper/eventHelper'
import { Append } from 'types'
import { createChatOptions } from 'helper/createChatOptions'

interface ConversationStarersProps {
  conversationStarters: string[]
  configColor?: string
  append: Append
  trackWidgetEvent: (event: WidgetEvent) => void
}

export const ConversationStarters = ({
  conversationStarters,
  configColor,
  append,
  trackWidgetEvent,
}: ConversationStarersProps): JSX.Element => {
  const handleConversationStarterClick = (starterMsg: string) => {
    trackWidgetEvent(createEvent(CONVERSATION_STARTER_CLICKED, starterMsg))
    append({ content: starterMsg, role: ChatRoleMap.user }, createChatOptions(ActionTypeMap.CONVERSATION_STARTER))
  }

  return (
    <div className={classes.conversationStarterContainer}>
      {conversationStarters.map(starterMsg => (
        <div key={starterMsg} className={classes.conversationItem}>
          <button
            onClick={() => handleConversationStarterClick(starterMsg)}
            className={classes.actionButton}
            style={{
              background: `${configColor}${_15PercentTransparencyInHex}`,
              //@ts-ignore
              '--hoverBg': configColor,
            }}>
            {starterMsg}
          </button>
        </div>
      ))}
    </div>
  )
}
