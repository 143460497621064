type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S

export const MESSAGE_SIZE = 20

type MessageTypes = 'streamable_text' | 'text' | 'generic_template' | 'attachment' | 'postback'
export const MessageType: Record<SnakeToCamelCase<MessageTypes>, MessageTypes> = {
  streamableText: 'streamable_text',
  text: 'text',
  genericTemplate: 'generic_template',
  attachment: 'attachment',
  postback: 'postback',
}

export type ChatRole = 'assistant' | 'user' | 'system'
export const ChatRoleMap: Record<ChatRole, ChatRole> = {
  assistant: 'assistant',
  user: 'user',
  system: 'system',
}

export type ActionType = 'QUICK_REPLY' | 'CONVERSATION_STARTER' | 'TEXT_MESSAGE'
export const ActionTypeMap: Record<ActionType, ActionType> = {
  QUICK_REPLY: 'QUICK_REPLY',
  CONVERSATION_STARTER: 'CONVERSATION_STARTER',
  TEXT_MESSAGE: 'TEXT_MESSAGE',
}
