import React, { JSX, Dispatch, SetStateAction } from 'react'

import * as S from './QuickReplyList.style'
import { ActionTypeMap, ChatRoleMap } from 'constants/index'
import { Append, QuickReplies } from 'types'
import { createChatOptions } from 'helper/createChatOptions'
import { QUICK_REPLY_CLICKED, WidgetEvent, createEvent } from 'helper/eventHelper'

interface QuickReplyListProps {
  quickReplies: string[]
  color: string
  append: Append
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  trackWidgetEvent: (event: WidgetEvent) => void
}

export const QuickReplyList = ({
  quickReplies,
  color,
  append,
  setQuickReplies,
  trackWidgetEvent,
}: QuickReplyListProps): JSX.Element => {
  const handleClickQuickReply = (reply: string) => {
    setQuickReplies({ messageId: '', quickReplies: [] })
    trackWidgetEvent(createEvent(QUICK_REPLY_CLICKED, reply))
    append({ content: reply, role: ChatRoleMap.user }, createChatOptions(ActionTypeMap.QUICK_REPLY))
  }
  return (
    <S.QuickReplyList>
      {quickReplies?.map(reply => (
        <S.QuickReplyItem onClick={() => handleClickQuickReply(reply)} color={color} key={reply}>
          {reply}
        </S.QuickReplyItem>
      ))}
    </S.QuickReplyList>
  )
}
