import React, { useContext, useEffect } from 'react'

import { AriaLiveContext } from 'context/AriaLiveContext'
import { SettingsType } from 'models/SettingsType'
import { MessageType } from 'models/MessageType'
import { IconBubble, IconCloseBubble } from 'icons/Icons'

import * as S from './Button.style'
import { BUBBLE_OPEN, createEvent, WidgetEvent } from '../../helper/eventHelper'

interface Props {
  isWidgetOpened: boolean
  settings: SettingsType
  toggleWidget: () => void
  botId: number | string
  messages: MessageType[]
  isButtonNeeded: boolean
  showUnreadIcon: boolean
  setShowPopup: (show: boolean) => void
  trackWidgetEvent: (event: WidgetEvent) => void
  greeting: string
}

export const Button: React.FC<Props> = ({
  isWidgetOpened,
  settings,
  toggleWidget,
  messages,
  isButtonNeeded,
  showUnreadIcon,
  setShowPopup,
  trackWidgetEvent,
  greeting,
  botId,
}) => {
  const { setIsEnableAriaLive } = useContext(AriaLiveContext)

  const listenerToggleWidget = event => {
    if (event.data.type === 'open-widget') {
      handleToggleWidget()
    }
  }

  useEffect(() => {
    window.addEventListener('message', listenerToggleWidget)
    return () => {
      window.removeEventListener('message', listenerToggleWidget)
    }
  }, [listenerToggleWidget])

  const handleToggleWidget = () => {
    if (!isWidgetOpened) {
      trackWidgetEvent(createEvent(BUBBLE_OPEN, greeting))
      setShowPopup(false)
    }
    toggleWidget()

    if (messages.length) {
      setIsEnableAriaLive(false)
    }
  }

  return (
    <S.Container data-testid="button" isButtonNeeded={isButtonNeeded}>
      <BubbleIcon
        botId={botId}
        handleToggleWidget={handleToggleWidget}
        settings={settings}
        isWidgetOpened={isWidgetOpened}
      />
      {showUnreadIcon && <S.UnreadLabel>1</S.UnreadLabel>}
    </S.Container>
  )
}

interface BubbleIconProps {
  botId: string | number
  handleToggleWidget: () => void
  settings: SettingsType
  isWidgetOpened: boolean
}
const BubbleIcon = ({ botId, handleToggleWidget, settings, isWidgetOpened }: BubbleIconProps) => {
  const botIdToCustomIconsMap = {
    '66445d8d1fabf9b25b83e53f': '/identalia.gif',
    '66d1946f475b3ce86582019e': '/identalia.it.gif',
  }

  const getButtonIconSrc = () => {
    return botId in botIdToCustomIconsMap ? (
      <S.CustomButtonWrapper onClick={handleToggleWidget} id="widget-button">
        <img src={botIdToCustomIconsMap[botId]} style={{ width: '60px', height: '60px', objectFit: 'cover' }} />
      </S.CustomButtonWrapper>
    ) : (
      <S.Button color={settings?.color} onClick={handleToggleWidget} id="widget-button">
        {isWidgetOpened ? <IconCloseBubble /> : <IconBubble />}
      </S.Button>
    )
  }

  return <>{getButtonIconSrc()}</>
}
