import React, { FC, ReactNode, useRef, useState } from 'react'

const ScrollToButtonContext = React.createContext({
  scrollBehavior: 'smooth',
  setScrollBehavior: null,
  scrollToBottom: null,
  messagesEnd: null,
})

const ScrollToButtonProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [scrollBehavior, setScrollBehavior] = useState('smooth')
  const messagesEnd = useRef(null)

  const scrollIntoViewWithDelay = (behavior = 'smooth') => {
    setTimeout(() => {
      if (messagesEnd.current) {
        messagesEnd.current.scrollIntoView({ behavior })
      }
    }, 0)
  }

  const scrollToBottom = (behavior = 'smooth') => {
    if (messagesEnd.current) {
      if (behavior !== 'smooth') {
        setScrollBehavior(behavior)
        scrollIntoViewWithDelay(behavior)
      } else {
        setScrollBehavior('smooth')
        scrollIntoViewWithDelay()
      }
    }
  }

  return (
    <ScrollToButtonContext.Provider
      value={{
        messagesEnd,
        scrollBehavior,
        setScrollBehavior,
        scrollToBottom,
      }}>
      {children}
    </ScrollToButtonContext.Provider>
  )
}

export { ScrollToButtonProvider, ScrollToButtonContext }
