import React from 'react'

const IframeContext = React.createContext({
  iframeSrc: null,
  showIframe: false,
  handleCloseIframe: null,
  handleOpenIframe: null,
})

const IframeProvider = props => {
  const [iframeSrc, setIframeSrc] = React.useState(null)
  const [showIframe, setShowIframe] = React.useState(false)

  const handleOpenIframe = url => {
    setIframeSrc(url)
    setShowIframe(true)
  }

  const handleCloseIframe = () => {
    setIframeSrc(null)
    setShowIframe(false)
  }

  return (
    <IframeContext.Provider value={{ iframeSrc, showIframe, handleOpenIframe, handleCloseIframe }}>
      {props.children}
    </IframeContext.Provider>
  )
}

export { IframeProvider, IframeContext }
