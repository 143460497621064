import styled from 'styled-components'

const App = styled.div<{ isFullScreenWidget: boolean; alignItems: string }>`
  display: flex;
  position: fixed;
  bottom: 0;
  right: ${({ alignItems }) => alignItems === 'flex-end' && '0'};
  left: ${({ alignItems }) => alignItems === 'flex-start' && '0'};
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ alignItems }) => alignItems || 'flex-end'};
  overflow: hidden;
  z-index: 10010;
  width: ${({ isFullScreenWidget }) => isFullScreenWidget && '100%'};
  height: ${({ isFullScreenWidget }) => isFullScreenWidget && '100%'};
  padding: ${({ isFullScreenWidget }) => isFullScreenWidget && '0 25px'};
  box-sizing: ${({ isFullScreenWidget }) => isFullScreenWidget && 'border-box'};
`

const ButtonWrap = styled.div<{ alignItems: string }>`
  position: relative;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-end'};
  justify-content: flex-end;
  float: right;
  flex-direction: column;
`

export { App, ButtonWrap }
