import React, { useEffect, JSX, MutableRefObject } from 'react'
import { useInView } from 'react-intersection-observer'
import { useScrollChatContainer } from './useScrollChatContainer'

interface ChatScrollAnchorProps {
  trackVisibility?: boolean
  scrollContainer: MutableRefObject<HTMLDivElement | null>
}

export const ChatScrollAnchor = ({ trackVisibility, scrollContainer }: ChatScrollAnchorProps): JSX.Element => {
  const { scrollToBottom } = useScrollChatContainer(scrollContainer)
  const { ref, entry, inView } = useInView({
    trackVisibility,
    delay: 100,
  })

  useEffect(() => {
    if (trackVisibility && !inView && scrollContainer?.current) {
      scrollToBottom()
    }
  }, [inView, entry, scrollContainer?.current?.scrollHeight, trackVisibility])

  return <div ref={ref} />
}
