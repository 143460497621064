export const calculateIframeHeightWithPopup = (
  greeting: string,
  conversationStarters: string[],
  addConversationStarersHeight: boolean,
  isMobile: boolean,
): number => {
  const averageCharsCountBeforeWrapping = 40
  const basePopupItemHeight = 39
  const baseBubbleHeight = 92
  // Sum of Popup + ConversationStarterContainer margin bottom in MessagePreview.style.ts
  const sumOfVerticalMargins = 42
  const popupBottomMargin = 16

  let height = 0

  height += Math.ceil(greeting.length / averageCharsCountBeforeWrapping) * basePopupItemHeight

  if (addConversationStarersHeight) {
    conversationStarters.forEach(cs => {
      height += (Math.floor(cs.length / averageCharsCountBeforeWrapping) || 1) * basePopupItemHeight
    })
    height += sumOfVerticalMargins
  } else {
    height += popupBottomMargin
  }

  const extraHeightInCaseOfMobile = 25

  if (isMobile) {
    height += extraHeightInCaseOfMobile
  }

  return height + baseBubbleHeight
}
