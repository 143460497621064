export const convert3To6DigitHex = (hex: string): string => {
  if (hex && /^#[0-9A-Fa-f]{3}$/.test(hex)) {
    const r = hex[1]
    const g = hex[2]
    const b = hex[3]
    const hex6 = `#${r}${r}${g}${g}${b}${b}`

    return hex6
  }
  return hex || ''
}
