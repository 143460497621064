import React from 'react'
import { IconCloseConversationStarters } from 'icons/Icons'
import * as S from './MessagesPreview.style'
import { ChatRoleMap } from '../../constants'
import { createEvent, POPUP_CLOSE, POPUP_OPEN, STARTER_OPEN } from '../../helper/eventHelper'

import { unreadIconDelay } from '../../app/config'

export const MessagesPreview = ({
  device,
  isMobile,
  greeting,
  isPopupsNeeded,
  openWidget,
  setShowPopup,
  setShowUnreadIcon,
  setUnreadIconTimeoutId,
  conversationStarters,
  settings,
  append,
  hideConversationStarters,
  conversationStarted,
  trackWidgetEvent,
  alignItems,
  widgetXPosition,
  resizeWidget,
  wasAutomaticallyOpened,
}) => {
  const handleClosePopup = e => {
    trackWidgetEvent(createEvent(POPUP_CLOSE, greeting))
    e.stopPropagation()
    setShowPopup(false)
    resizeWidget()
    const unreadIconTimeoutId = setTimeout(() => setShowUnreadIcon(true), unreadIconDelay)
    setUnreadIconTimeoutId(unreadIconTimeoutId)
  }

  const handlePreviewClick = () => {
    trackWidgetEvent(createEvent(POPUP_OPEN, greeting))
    openWidget()
  }

  const handleConversationStarterClick = starterMessage => {
    trackWidgetEvent(createEvent(STARTER_OPEN, starterMessage))
    openWidget()
    append({ content: starterMessage, role: ChatRoleMap.user })
  }

  const showGreetingsAndConvoStarters = isPopupsNeeded && !conversationStarted && !wasAutomaticallyOpened

  return (
    <>
      <S.MessagesPreview alignItems={alignItems} isPopupsNeeded={showGreetingsAndConvoStarters}>
        <S.Popup
          width={device.width}
          isMobile={isMobile}
          tabIndex={0}
          hideConversationStarters={hideConversationStarters}
          onClick={handlePreviewClick}>
          <S.Text>{greeting}</S.Text>
          <S.Icon
            aria-label="close popup - button"
            id="closePopup"
            data-testid="toggle"
            tabIndex={0}
            onClick={handleClosePopup}>
            <IconCloseConversationStarters />
          </S.Icon>
        </S.Popup>
        {!hideConversationStarters &&
          conversationStarters.map((starterMessage, index) => (
            <S.ConversationStarterContainer
              widgetXPosition={widgetXPosition}
              key={starterMessage + index}
              width={device.width}>
              <S.ConversationStarter
                color={settings.color}
                width={device.width}
                tabIndex={0}
                onClick={() => handleConversationStarterClick(starterMessage)}>
                <S.Text>{starterMessage}</S.Text>
              </S.ConversationStarter>
            </S.ConversationStarterContainer>
          ))}
      </S.MessagesPreview>
    </>
  )
}
