import React, { useContext, useMemo } from 'react'
import MessageBody from '../MessageBody'

import { checkShowLogo, defineMessageBorder } from '../../containers/Chat/config'
import { AriaLiveContext } from 'context/AriaLiveContext'
import { usePreventFocus } from 'hooks/usePreventFocus'

import * as S from './MessageGroup.style'
import _ from 'lodash'

const MessageGroup = ({
  group,
  device,
  quickReplies,
  setQuickReplies,
  showQuickReplies,
  parentURL,
  settings,
  isMobile,
  isFullScreenWidget,
  isLastGroup,
  postMessage,
  isBotReplying,
  append,
  trackWidgetEvent,
}) => {
  const { isEnableAriaLive } = useContext(AriaLiveContext)
  const preventFocus = usePreventFocus()

  const lastReactionElementIndex = useMemo(() => {
    let lastIndex
    group.some((val, index, array) => {
      const reverseIndex = array.length - 1 - index
      const enableReaction = _.get(array, `${reverseIndex}.enableReaction`)
      return enableReaction && (lastIndex = reverseIndex)
    })
    return lastIndex
  }, [group])

  return (
    <S.MessageGroup
      aria-live={isEnableAriaLive && group[0].isFromBot && isLastGroup && !preventFocus ? 'assertive' : 'off'}
      aria-atomic="true">
      {group?.map((message, msgIndex) => (
        <MessageBody
          key={message.id || msgIndex}
          device={device}
          message={message}
          quickReplies={quickReplies}
          setQuickReplies={setQuickReplies}
          showQuickReplies={showQuickReplies}
          trackWidgetEvent={trackWidgetEvent}
          append={append}
          parentURL={parentURL}
          settings={settings}
          isMobile={isMobile}
          isFullScreenWidget={isFullScreenWidget}
          showLogo={checkShowLogo(group, msgIndex)}
          messageBorder={defineMessageBorder(group, msgIndex)}
          postMessage={msg => postMessage(msg)}
          isLastMessage={isLastGroup && group.length === msgIndex + 1}
          isBotReplying={isBotReplying}
          showReactionElementText={msgIndex === lastReactionElementIndex}
        />
      ))}
    </S.MessageGroup>
  )
}

export default MessageGroup
