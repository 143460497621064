import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react'

import Textarea from 'react-textarea-autosize'
import { SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { IconSendMessage } from 'icons/Icons'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { INPUT_OFF } from 'constants/actionsType'
import { CADET_BLUE, MINE_SHAFT_BLACK, _15PercentTransparencyInHex } from 'constants/colors'

import * as S from './UserInput.style'
import { UseChatHelpers } from 'ai/react/dist'
import { QuickReplies } from 'types'

interface Props {
  textDisabled: boolean
  inputTogglerValue: string
  settings: SettingsType
  isFullScreenWidget: boolean
  isRtl: boolean
  chatId: number
  device: DeviceType
  showIframe: boolean
  postMessage: (m: any) => void
  screenSize: DeviceType
  disableInput: boolean
  input: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSubmit: UseChatHelpers['handleSubmit']
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  isMobile?: boolean
}

const enterKeyCode = 13
const isEmptyInput = (inputValue: string | undefined | null) => !inputValue || !inputValue.trim()

export const UserInput: React.FC<Props> = ({
  textDisabled,
  inputTogglerValue,
  settings,
  isRtl,
  disableInput,
  input,
  isMobile,
  handleInputChange,
  handleSubmit,
  setQuickReplies,
}) => {
  const inputRef = useRef(null)
  const preventFocus = usePreventFocus()
  const isHiddenTextInput = useMemo(() => inputTogglerValue === INPUT_OFF, [inputTogglerValue])

  const handleUserKeyPress = useCallback(event => {
    const isPrintableKey = event.key.length === 1 && !event.metaKey && !event.ctrKey

    if (document.activeElement !== inputRef.current && isPrintableKey) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleEnter = e => {
    if (e.charCode === enterKeyCode && !disableInput) {
      e.preventDefault()
      if (isHiddenTextInput) return
      handleSendMessage(e)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleInputChange(e)
  }

  const handleSendMessage = (e: FormEvent<HTMLFormElement>) => {
    if (isEmptyInput(input)) return
    setQuickReplies({ messageId: '', quickReplies: [] })
    handleSubmit(e)
  }

  return (
    <S.UserInput textDisabled={textDisabled} isMobile={isMobile}>
      <Textarea
        maxRows={2}
        maxLength={2000}
        placeholder={settings?.inputPlaceholder}
        dir={isRtl ? 'rtl' : 'ltr'}
        id="messaging-user-input"
        autoComplete="off"
        value={input}
        onChange={handleChange}
        onKeyPress={handleEnter}
        tabIndex={preventFocus ? -1 : 0}
        data-testid="input"
        ref={inputRef}
        color={settings.color}
        style={{
          background: '#fff',
          width: '100%',
          visibility: isHiddenTextInput ? 'hidden' : 'visible',
          boxSizing: 'border-box',
          padding: '8px',
          borderRadius: '10px',
          color: MINE_SHAFT_BLACK,
          border: `1px solid ${settings.color}`,
          resize: 'none',
          opacity: isHiddenTextInput ? 0 : 1,
          outline: '0',
          paddingRight: 80,
          boxShadow: `0 0 0 3px ${settings.color + _15PercentTransparencyInHex}`,
        }}
      />
      <S.ButtonWrapper>
        <S.Button
          color={settings.color}
          onClick={handleSendMessage}
          aria-label="send message"
          tabIndex={preventFocus ? -1 : 0}
          data-testid="button"
          isHiddenTextInput={isHiddenTextInput}
          disabled={disableInput}
          isMobile={isMobile}>
          Send
          <IconSendMessage width={21} height={21} color={disableInput ? CADET_BLUE : settings?.color} />
        </S.Button>
      </S.ButtonWrapper>
    </S.UserInput>
  )
}
