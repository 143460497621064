/* eslint-disable */
import React from 'react'

export class PrevIcon extends React.Component {
  render() {
    return (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path d="M7 13L1 7L7 1" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class NextIcon extends React.Component {
  render() {
    return (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path d="M1 13L7 7L1 1" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export class DownloadIcon extends React.Component {
  render() {
    return (
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none">
        <path
          d="M1 7.66602V8.99935C1 9.49027 1.39797 9.88824 1.88889 9.88824H8.11111C8.60203 9.88824 9 9.49027 9 8.99935V7.66602"
          stroke="#1658F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.22217 5.44531L4.99995 7.22309L6.77772 5.44531"
          stroke="#1658F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M5 1V7.22222" stroke="#1658F3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }
}

export const IconUser = ({ className, ...props }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10.6666C14.8954 10.6666 14 11.562 14 12.6666C14 13.7712 14.8954 14.6666 16 14.6666C17.1046 14.6666 18 13.7712 18 12.6666C18 11.562 17.1046 10.6666 16 10.6666ZM12.6667 12.6666C12.6667 10.8256 14.1591 9.33325 16 9.33325C17.8409 9.33325 19.3333 10.8256 19.3333 12.6666C19.3333 14.5075 17.8409 15.9999 16 15.9999C14.1591 15.9999 12.6667 14.5075 12.6667 12.6666ZM10.9763 18.3096C11.6014 17.6844 12.4493 17.3333 13.3333 17.3333H18.6667C19.5507 17.3333 20.3986 17.6844 21.0237 18.3096C21.6488 18.9347 22 19.7825 22 20.6666V21.9999C22 22.3681 21.7015 22.6666 21.3333 22.6666C20.9651 22.6666 20.6667 22.3681 20.6667 21.9999V20.6666C20.6667 20.1362 20.456 19.6274 20.0809 19.2524C19.7058 18.8773 19.1971 18.6666 18.6667 18.6666H13.3333C12.8029 18.6666 12.2942 18.8773 11.9191 19.2524C11.544 19.6274 11.3333 20.1362 11.3333 20.6666V21.9999C11.3333 22.3681 11.0349 22.6666 10.6667 22.6666C10.2985 22.6666 10 22.3681 10 21.9999V20.6666C10 19.7825 10.3512 18.9347 10.9763 18.3096Z"
        fill={props.color || '#275288'}
      />
    </svg>
  )
}

export const IconSendMessage = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="custom/ send-01">
        <rect width="16" height="16" transform="translate(0.5)" fill="white" fillOpacity="0.01" />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8336 3.37324L9.86495 13.5429C9.85402 13.5709 9.84367 13.5974 9.83384 13.6225C9.82405 13.5974 9.81373 13.5709 9.80283 13.5428L8.08396 9.12287L13.8336 3.37324ZM7.37685 8.41577L13.1265 2.66613L2.95684 6.63477C2.92883 6.64571 2.90233 6.65605 2.87725 6.66588C2.90234 6.67567 2.92885 6.68599 2.95688 6.69689L7.37685 8.41577ZM14.3113 1.14282C14.4288 1.10868 14.6314 1.05889 14.8436 1.12978C15.092 1.21277 15.287 1.40771 15.3699 1.65613C15.4408 1.86831 15.391 2.07089 15.3569 2.18844C15.3204 2.31413 15.261 2.46636 15.1974 2.62908L10.789 13.9258C10.7181 14.1074 10.6533 14.2735 10.5909 14.3998C10.5351 14.5126 10.4285 14.7118 10.2178 14.8212C9.97661 14.9465 9.68952 14.9463 9.44853 14.8208C9.23794 14.7111 9.13154 14.5118 9.07591 14.3989C9.01363 14.2726 8.94902 14.1064 8.87835 13.9246L7.11875 9.39995C7.11687 9.39511 7.1151 9.39057 7.11344 9.38629C7.10915 9.38462 7.10461 9.38285 7.09977 9.38097L2.5751 7.62138C2.39333 7.55071 2.22715 7.4861 2.10081 7.42381C1.98797 7.36818 1.78866 7.26178 1.67895 7.0512C1.55339 6.8102 1.55322 6.52312 1.6785 6.28197C1.78796 6.07126 1.98714 5.96462 2.09992 5.90886C2.22618 5.84643 2.39228 5.78162 2.57395 5.71074L13.8707 1.30225C14.0334 1.23874 14.1856 1.17933 14.3113 1.14282Z"
          fill="#101D46"
        />
      </g>
    </svg>
  )
}

export const MinusIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MiniMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M4 14H10V20" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 10H14V4" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10L21 3" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MaxiMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M3 15V21H9" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21 9L21 3L15 3"
      stroke={color || '#616581'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 3L14 10" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const StarIcon = props => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
        stroke={props.color || '#B0B2C0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconCloseConversationStarters = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.136719" width="20" height="20" rx="10" fill="white" />
      <rect x="0.636719" y="0.5" width="19" height="19" rx="9.5" stroke="#000713" strokeOpacity="0.624" />
      <rect width="16" height="16" transform="translate(2.13672 2)" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7038 6.30044C14.9434 6.06092 14.9434 5.67258 14.7038 5.43306C14.4643 5.19354 14.076 5.19354 13.8365 5.43306L10.1368 9.13271L6.43717 5.43306C6.19764 5.19354 5.8093 5.19354 5.56978 5.43306C5.33026 5.67258 5.33026 6.06092 5.56978 6.30044L9.26942 10.0001L5.56978 13.6997C5.33026 13.9393 5.33026 14.3276 5.56978 14.5671C5.8093 14.8066 6.19764 14.8066 6.43717 14.5671L10.1368 10.8675L13.8365 14.5671C14.076 14.8066 14.4643 14.8066 14.7038 14.5671C14.9434 14.3276 14.9434 13.9393 14.7038 13.6997L11.0042 10.0001L14.7038 6.30044Z"
        fill="#000713"
        fillOpacity="0.624"
      />
    </svg>
  )
}

export const IconBubble = () => {
  return (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16904e-06 9.65701C-0.000845831 8.38778 0.246962 7.13086 0.729234 5.95819C1.21149 4.78555 1.91874 3.7202 2.81048 2.82316C3.70221 1.92613 4.76093 1.21503 5.92597 0.730604C7.09103 0.246159 8.33954 -0.00211312 9.6 1.35476e-05H22.4C27.7008 1.35476e-05 32 4.34196 32 9.65701V29H9.6C4.2992 29 2.16904e-06 24.6581 2.16904e-06 19.343V9.65701ZM28.8 25.7778V9.65701C28.7958 7.95004 28.1198 6.31435 26.9202 5.10823C25.7206 3.90212 24.0952 3.22393 22.4 3.22224H9.6C8.75976 3.22011 7.92736 3.38504 7.15056 3.70753C6.37375 4.03005 5.66781 4.50379 5.07322 5.10161C4.47863 5.69943 4.00709 6.40956 3.68565 7.19129C3.36419 7.973 3.19915 8.81094 3.2 9.65701V19.343C3.20424 21.05 3.88018 22.6857 5.07978 23.8918C6.27936 25.0979 7.9048 25.7762 9.6 25.7778H28.8ZM19.2 12.8889H22.4V16.1111H19.2V12.8889ZM9.6 12.8889H12.8V16.1111H9.6V12.8889Z"
        fill="white"
      />
    </svg>
  )
}

export const IconCloseBubble = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1381 1.80473C15.3984 1.54438 15.3984 1.12227 15.1381 0.861926C14.8777 0.601567 14.4556 0.601567 14.1952 0.861926L7.99999 7.05718L1.80473 0.861926C1.54438 0.601567 1.12227 0.601567 0.861926 0.861926C0.601567 1.12227 0.601567 1.54438 0.861926 1.80473L7.05718 7.99999L0.861926 14.1952C0.601567 14.4556 0.601567 14.8777 0.861926 15.1381C1.12227 15.3984 1.54438 15.3984 1.80473 15.1381L7.99999 8.94281L14.1952 15.1381C14.4556 15.3984 14.8777 15.3984 15.1381 15.1381C15.3984 14.8777 15.3984 14.4556 15.1381 14.1952L8.94281 7.99999L15.1381 1.80473Z"
        fill="white"
      />
    </svg>
  )
}
