export const POPUP_OPEN = 'POPUP_OPEN_WIDGET'

export const POPUP_CLOSE = 'CLOSE_POPUP'
export const STARTER_OPEN = 'STARTER_OPEN_WIDGET'
export const BUBBLE_OPEN = 'BUBBLE_OPEN_WIDGET'

export const CONVERSATION_STARTER_CLICKED = 'CONVERSATION_STARTER_CLICKED'
export const QUICK_REPLY_CLICKED = 'QUICK_REPLY_CLICKED'

export type WidgetEvent = Record<'type' | 'text', string>
export const createEvent = (type: string, text: string): WidgetEvent => {
  return {
    type,
    text,
  }
}
