import { API_URL } from '../config/config'

export const createJsonHeaders = () => {
  const headers = createHeaders()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return headers
}

function createHeaders() {
  return new Headers()
}

export const getWidgetConfig = (botId, originUrl) => {
  return fetch(`${API_URL}/api/public/bot/${botId}/config?url=${encodeURIComponent(originUrl)}`, {
    headers: createJsonHeaders(),
    method: 'GET',
  }).then(response => {
    if (response.ok) {
      return response.json().then(config => config)
    }
  })
}

export const getQuickReplies = (botId, chatId, tempId) => {
  return fetch(`${API_URL}/api/public/bot/${botId}/chat/${chatId}/quick-replies?tempId=${tempId}`, {
    method: 'POST',
    headers: createJsonHeaders(),
  }).then(response => {
    if (response.ok) return response.json().then(quickReplies => quickReplies)
  })
}

export const trackWidgetEvent = (backendUrl, botId, visitorId, event) => {
  fetch(`${backendUrl}/api/public/bot/${botId}/visitor/${visitorId}/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-widget': 'true',
    },
    body: JSON.stringify({ event }),
    cache: 'no-store',
  })
}
