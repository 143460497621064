import React, { JSX } from 'react'
import './Typing.css'

export const Typing = (): JSX.Element => {
  return (
    <div className="chat__bubble chat__bubble-typing">
      <div className="loading">
        <div className="dot one"></div>
        <div className="dot two"></div>
        <div className="dot three"></div>
      </div>
    </div>
  )
}
